@import 'theme';

nz-page-header {
  background-color: white !important;
}

::ng-deep nz-content > * {
  flex: 1;
}

svg[data-transparent] {
  fill: transparent;
}

nz-content {
  & > div {
    height: 100%;
  }
}

/* @media (min-width: 1024px) {
  .ant-drawer-content-wrapper {
    height: calc(100vh) !important;


  }
} */
