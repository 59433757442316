quill-editor {
  width: 100%;

  .ql-toolbar {
    -webkit-border-top-left-radius: 5px;
    -webkit-border-top-right-radius: 5px;
    -moz-border-radius-topleft: 5px;
    -moz-border-radius-topright: 5px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border: 1px solid #d9d9d9 !important;
  }

  .ql-container {
    -webkit-border-bottom-left-radius: 5px;
    -webkit-border-bottom-right-radius: 5px;
    -moz-border-radius-bottomleft: 5px;
    -moz-border-radius-bottomright: 5px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border: 1px solid #d9d9d9 !important;
    border-top: 0 !important;
  }
}

quill-view-html {
  .ql-editor {
    padding: 0px !important;


    p {
      min-height: 14px;
    }

    ul {
      margin-block-start: 0.5em !important;
      margin-block-end: 0.5em !important;
      li {
        list-style-type: disc;
        padding-left: 0;
      }
    }

    ol {
      padding-inline-start: 40px !important;
      margin-block-start: 0.5em !important;
      margin-block-end: 0.5em !important;
      li {
        list-style-type: decimal;
        padding-left: 0;
      }
    }
  }
}
